import React, { Component } from "react";
import Slider from "react-slick";

import "./timePickerSlider.module.css";
import { getDocument } from "../../../../util/Util";

interface TimePickerSliderProps {
  initialHour?: number;
  initialMinute?: number;
  datePickerCallBack: (string) => void;
}

interface TimePickerSliderState {
  minutes?: string[];
  hours?: string[];
  amPmEnabled: boolean;
}

const defaultHours = () => {
  const times = [];
  for (let i = 0; i < 24; i += 1) {
    times.push(`0${i}`.slice(-2));
  }
  return times;
};

const defaultMinutes = () => {
  const times = [];
  for (let i = 0; i < 60; i += 1) {
    times.push(`0${i}`.slice(-2));
  }
  return times;
};

class TimePickerSlider extends Component<
  TimePickerSliderProps,
  TimePickerSliderState
> {
  private readonly slider1: React.RefObject<Slider>;

  private readonly slider2: React.RefObject<Slider>;

  private readonly slider3: React.RefObject<Slider>;

  private hour: number;

  private minute: number;

  constructor(props: TimePickerSliderProps) {
    super(props);
    this.state = {
      hours: defaultHours(),
      minutes: defaultMinutes(),
      amPmEnabled: false,
    };
    this.slide1 = this.slide1.bind(this);
    this.slide2 = this.slide2.bind(this);
    this.slide3 = this.slide3.bind(this);
    this.slider1 = React.createRef<Slider>();
    this.slider2 = React.createRef<Slider>();
    this.slider3 = React.createRef<Slider>();
  }

  componentDidMount(): void {
    const scrollableTimer =
      getDocument(document).querySelector(".time-picker-root");
    const { initialHour, initialMinute } = this.props;

    scrollableTimer.addEventListener("wheel", (e: WheelEvent) => {
      const { deltaY } = e;
      const contentHeight = scrollableTimer.scrollHeight;
      const visibleHeight = scrollableTimer.clientHeight;
      const { scrollTop } = scrollableTimer;

      if (
        (scrollTop === 0 && deltaY < 0) ||
        (visibleHeight + scrollTop === contentHeight && deltaY > 0)
      )
        e.preventDefault();

      if (this.slider1) {
        this.slide1(e);
      }
      if (this.slider2) {
        this.slide2(e);
      }
      if (this.slider3) {
        this.slide3(e);
      }
    });

    if (initialHour) {
      this.slider1.current.slickGoTo(initialHour);
    }
    if (initialMinute) {
      this.slider2.current.slickGoTo(initialMinute);
    }
  }

  handleSliderAfterChange(value: string, field: string): void {
    switch (field) {
      case "hour":
        this.hour = parseInt(value, 10);
        this.setDatePickerCallBack();
        break;
      case "minute":
        this.minute = parseInt(value, 10);
        this.setDatePickerCallBack();
        break;
      default:
        break;
    }
  }

  setHour(direction: string): void {
    getDocument(document)
      .querySelectorAll(".hours")
      .forEach((item) => {
        const hourData = item.querySelector("[class*=slick-active]");
        const hourCurrent = Number(hourData.lastChild.textContent);
        if (direction === "next") {
          this.hour = hourCurrent + 1;
          if (this.hour === 24) {
            this.hour = 0;
          }
        } else {
          this.hour = hourCurrent - 1;
          if (this.hour < 0) {
            this.hour = 23;
          }
        }
      });
  }

  setMinute(direction: string): void {
    getDocument(document)
      .querySelectorAll(".minutes")
      .forEach((item) => {
        const minuteData = item.querySelector("[class*=slick-active]");
        const minuteCurrent = Number(minuteData.lastChild.textContent);
        if (direction === "next") {
          this.minute = minuteCurrent + 1;
          if (this.minute > 59) {
            this.minute = 0;
          }
        } else {
          this.minute = minuteCurrent - 1;
          if (this.minute < 0) {
            this.minute = 59;
          }
        }
      });
  }

  setDatePickerCallBack(): void {
    const { datePickerCallBack } = this.props;

    if (typeof this.hour === "undefined") {
      getDocument(document)
        .querySelectorAll(".hours")
        .forEach((item) => {
          const hourData = item.querySelector("[class*=slick-active]");
          this.hour = Number(hourData.lastChild.textContent);
        });
    }

    if (typeof this.minute === "undefined") {
      getDocument(document)
        .querySelectorAll(".minutes")
        .forEach((item) => {
          const minuteData = item.querySelector("[class*=slick-active]");
          this.minute = Number(minuteData.lastChild.textContent);
        });
    }
    const hourString = this.hour < 10 ? `0${this.hour}` : this.hour;
    const minuteString = this.minute < 10 ? `0${this.minute}` : this.minute;
    datePickerCallBack(`${hourString}:${minuteString}`);
  }

  next(slider: Slider, type: string): void {
    slider.slickNext();
    if (type === "hour") this.setHour("next");
    if (type === "minute") this.setMinute("next");
    this.setDatePickerCallBack();
  }

  previous(slider: Slider, type: string): void {
    slider.slickPrev();
    if (type === "hour") this.setHour("previous");
    if (type === "minute") this.setMinute("previous");
    this.setDatePickerCallBack();
  }

  slide1(y: WheelEvent): void {
    y.stopPropagation();
    if ((y.target as Element).closest(".slider1")) {
      if (y.deltaY > 0) {
        this.next(this.slider1.current, "hour");
      } else {
        this.previous(this.slider1.current, "hour");
      }
    }
  }

  slide2(y: WheelEvent): void {
    y.stopPropagation();
    if ((y.target as Element).closest(".slider2")) {
      if (y.deltaY > 0) {
        this.next(this.slider2.current, "minute");
      } else {
        this.previous(this.slider2.current, "minute");
      }
    }
  }

  slide3(y: WheelEvent): void {
    y.stopPropagation();
    if ((y.target as Element).closest(".slider3")) {
      if (y.deltaY > 0) {
        this.slider3.current.slickNext();
      } else {
        this.slider3.current.slickPrev();
      }
    }
  }

  render(): JSX.Element {
    const { hours, minutes, amPmEnabled } = this.state;
    const settings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      speed: 80,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      arrows: false,
      adaptiveHeight: false,
    };
    return (
      <div className="time-picker-root" data-cr="de-time-picker">
        <Slider
          centerMode={settings.centerMode}
          infinite={settings.infinite}
          slidesToShow={settings.slidesToShow}
          speed={settings.speed}
          slidesToScroll={settings.slidesToScroll}
          vertical={settings.vertical}
          verticalSwiping={settings.verticalSwiping}
          swipeToSlide={settings.swipeToSlide}
          arrows={settings.arrows}
          adaptiveHeight={settings.adaptiveHeight}
          className="slider-entity hours slider1"
          ref={this.slider1}
          afterChange={(index: number) =>
            this.handleSliderAfterChange(hours[index], "hour")
          }
        >
          {hours.map((hour) => (
            <div key={hour}>{hour}</div>
          ))}
        </Slider>
        <Slider
          centerMode={settings.centerMode}
          infinite={settings.infinite}
          slidesToShow={settings.slidesToShow}
          speed={settings.speed / 4}
          slidesToScroll={settings.slidesToScroll}
          vertical={settings.vertical}
          verticalSwiping={settings.verticalSwiping}
          swipeToSlide={settings.swipeToSlide}
          arrows={settings.arrows}
          adaptiveHeight={settings.adaptiveHeight}
          className="slider-entity minutes slider2"
          ref={this.slider2}
          afterChange={(index: number) =>
            this.handleSliderAfterChange(minutes[index], "minute")
          }
        >
          {minutes.map((min) => (
            <div key={min}>{min}</div>
          ))}
        </Slider>
        {amPmEnabled && (
          <Slider
            centerMode={settings.centerMode}
            infinite={settings.infinite}
            slidesToShow={settings.slidesToShow}
            speed={settings.speed}
            slidesToScroll={settings.slidesToScroll}
            vertical={settings.vertical}
            verticalSwiping={settings.verticalSwiping}
            swipeToSlide={settings.swipeToSlide}
            arrows={settings.arrows}
            adaptiveHeight={settings.adaptiveHeight}
            className="slider-entity ampm slider3"
            ref={this.slider3}
          >
            <div>AM</div>
            <div>PM</div>
          </Slider>
        )}
        <div className="mask">
          <div className="mask-item">
            <span>hours</span>
          </div>
          <div className="mask-item">
            <span>minutes</span>
          </div>
          {amPmEnabled && <div className="mask-item" />}
        </div>
      </div>
    );
  }
}
export default TimePickerSlider;
