// Generic
import React, { useEffect, useMemo, useState } from "react";
// Styles
import "./genericInfoCardTask.module.css";
// Components
import {
  GenericInfoCardType,
  incidentStepType,
  InfoField,
  InfoFieldText,
  InfoFieldTypes,
  InfoFieldUrl,
} from "compass-commons";
import InfoFieldTextComponent from "./infoFieldsData/InfoFieldTextComponent";
import InfoFieldUrlComponent from "./infoFieldsData/InfoFieldUrlComponent";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

interface GenericInfoCardTaskProps<T extends InfoField> {
  infoFieldDTOs: T[];
}

export const InfoFieldToGenericInfoMapper = Object.freeze({
  TEXT: "DATA",
  PHOTO: "PHOTO",
  URL: "DATA",
});

const GenericInfoCardTask = <T extends InfoField>({
  infoFieldDTOs,
}: GenericInfoCardTaskProps<T>): JSX.Element => {
  const [mediaData, setMediaData] = useState<{
    [key in GenericInfoCardType]: JSX.Element[];
  }>({
    [GenericInfoCardType.PHOTO]: [],
    [GenericInfoCardType.DATA]: [],
  });

  // Mapping from InfoField data to Component
  const InfoFieldToComponent = {
    [InfoFieldTypes.TEXT]: (infoText: InfoField) => (
      <InfoFieldTextComponent infoFieldText={infoText as InfoFieldText} />
    ),
    [InfoFieldTypes.URL]: (infoUrl: InfoField) => (
      <InfoFieldUrlComponent infoFieldUrl={infoUrl as InfoFieldUrl} />
    ),
  };

  const getInfoData = useMemo(() => mediaData.DATA, [mediaData.DATA]);

  const prepareMediaData = () => {
    const tempMediaData = {
      [GenericInfoCardType.PHOTO]: [],
      [GenericInfoCardType.DATA]: [],
    };

    infoFieldDTOs.forEach((infof: InfoField) => {
      if (InfoFieldTypes.PHOTO !== infof.infoFieldType) {
        tempMediaData[InfoFieldToGenericInfoMapper[infof.infoFieldType]].push(
          InfoFieldToComponent[infof.infoFieldType](infof)
        );
      }
    });

    setMediaData(tempMediaData);
  };

  useEffect(() => {
    prepareMediaData();
  }, []);

  return (
    <div
      data-cr={incidentStepType.GENERIC_INFO.toLowerCase()}
      data-cy={incidentStepType.GENERIC_INFO.toLowerCase()}
      className="operation-geninfo-card__wrapper"
    >
      <div className="operation-geninfo-card__container">
        {(getInfoData.length && (
          <div className="operation-geninfo-card__info">{...getInfoData}</div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default GenericInfoCardTask;
