import React, { useCallback, useEffect, useState } from "react";
import {
  FilterCriteria,
  GroupInfoFilterCriteria,
  ResultViewEnum,
  sessionStorageIdentifiers,
  useGetOrganization,
  useI18n,
} from "compass-commons";
import { Button, Checkbox } from "dms-lib";
import FilterCriteriaBuilder from "../../../models/filters/FilterCriteriaBuilder";
import Priorities from "./behavioursAndPriorities/Priorities";
import Sites from "./sites/Sites";
import TimePeriods from "../../commons/timePeriods/TimePeriods";
import "./filteringCriteria.module.css";
import FirstResponse from "./firstResponse/FirstResponse";
import TimeOfResponse from "./timeOfResponse/TimeOfResponse";
import DataExplorerService from "../../../services/DataExplorerService";
import IncidentTypes from "./incidentTypes/IncidentTypes";
import { IncidentSearchResultDTO } from "../../../models/filters/IncidentSearchResultDTO";
import ResponseTimeInterval from "../../../models/filters/ResponseTimeInterval";
import StateService from "../../../services/StateService";
import { useStateContext } from "../../../contexts/StateContext";
import SourceTypeEnum from "../../../models/filters/SourceTypeEnum";
import GroupInfoSearchResult from "../../../models/incidents/GroupInfoSearchResult";
import { defaultFilterLevels } from "../../../util/Constants";
import { StateHolder } from "../../../models/state/StateHolder";
import IncidentListTooLargeError from "../../../errors/IncidentListTooLargeError";
import { SitesOptions } from "../../../models/filters/SitesOptions";
import { IncidentTypeDTO } from "../../../models/filters/IncidentTypeDTO";
import { getInitStartDate, isValidDate } from "../../../util/Util";

interface FilteringCriteriaProps {
  callback?: (value) => void;
}

const FilteringCriteria = (props: FilteringCriteriaProps): JSX.Element => {
  const { callback } = props;

  const stateService: StateService = useStateContext();
  const {
    searchLoading,
    currentIncidentSearchResult,
    currentGroupInfoSearchResult,
    resultsPanelHidden,
    filterCriteriaHolder,
    sourceHolder,
    timeOfResponseDisabled,
    firstResponseDisabled,
    selectedResultsView,
    sunburstQuery,
    filterPanelHidden,
    alertSubject,
    selectedGroupInfoSearchResult,
    incidentListLimitReached,
    selectedIncidentId,
    detailsPanelHidden,
  } = stateService;
  const { t: translate } = useI18n();

  const organizationId = useGetOrganization();
  // The properties are used to format the date and in the future those could be provided by the user
  const includeTime = true;
  const dateFormat = "yyyy/MM/dd";
  const finalDateFormat = includeTime ? `${dateFormat} HH:mm` : dateFormat;

  if (sessionStorage.getItem(sessionStorageIdentifiers.STATE_HOLDER)) {
    const stateHolder = JSON.parse(
      sessionStorage.getItem(sessionStorageIdentifiers.STATE_HOLDER)
    ) as StateHolder;
    if (stateHolder.filterCriteria) {
      filterCriteriaHolder.next(stateHolder.filterCriteria);
    }
    if (stateHolder.incidentSearchResult || stateHolder.groupInfoSearchResult) {
      resultsPanelHidden.next(false);
    }
    stateHolder.filterCriteria = null;
    sessionStorage.setItem(
      sessionStorageIdentifiers.STATE_HOLDER,
      JSON.stringify(stateHolder)
    );
  }

  const [clearIsClicked, setClearIsClicked] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [sites, setSites] = useState(filterCriteriaHolder.getValue().sites);
  const [startDate, setStartDate] = useState(
    filterCriteriaHolder.getValue().startDate != null
      ? getInitStartDate(filterCriteriaHolder.getValue().startDate)
      : getInitStartDate(null)
  );
  const [endDate, setEndDate] = useState(
    filterCriteriaHolder.getValue().endDate != null
      ? getInitStartDate(filterCriteriaHolder.getValue().endDate)
      : new Date()
  );
  const [incidentPriorities, setIncidentPriorities] = useState(
    filterCriteriaHolder.getValue().incidentPriorities
  );
  const [includeComment, setIncludeComment] = useState(
    filterCriteriaHolder.getValue().includeComment
  );
  const [includeFiles, setIncludeFiles] = useState(
    filterCriteriaHolder.getValue().includeFiles
  );

  const [includeOnDemand, setIncludeOnDemand] = useState(
    filterCriteriaHolder.getValue().includeOnDemand
  );

  const [incidentTypes, setIncidentTypes] = useState(
    filterCriteriaHolder.getValue().incidentTypes
  );

  const [operators, setOperators] = useState(
    filterCriteriaHolder.getValue().operators
  );
  const [firstResponse, setFirstResponse] = useState(
    filterCriteriaHolder.getValue().firstResponseTime
  );
  const [timeOfResponse, setTimeOfResponse] = useState(
    filterCriteriaHolder.getValue().timeOfResponse
  );
  const [resultView, setResultView] = useState(ResultViewEnum.GRAPHIC);

  const [sourceHolderCamera, setSourceHolderCamera] = useState(
    sourceHolder.getValue().get(SourceTypeEnum.CAMERA)
  );

  const [sourceHolderSensor, setSourceHolderSensor] = useState(
    sourceHolder.getValue().get(SourceTypeEnum.SENSOR)
  );

  const [sourceHolderDoor, setSourceHolderDoor] = useState(
    sourceHolder.getValue().get(SourceTypeEnum.DOOR)
  );

  const [sourceHolderOther, setSourceHolderOther] = useState(
    sourceHolder.getValue().get(SourceTypeEnum.OTHER)
  );

  useEffect(() => {
    const selectedResultsViewSub = selectedResultsView.subscribe((val) => {
      if (val) {
        setResultView(val);
      }
    });

    return () => {
      selectedResultsViewSub.unsubscribe();
    };
  }, [selectedResultsView]);

  const getSources = () => {
    const arr = new Array<string>();
    sourceHolder.getValue()?.forEach((val: Array<string>, key: string) => {
      if (val) {
        arr.push(...val);
      }
    });
    return arr;
  };

  const prepareCriteria = (): FilterCriteria => {
    const builder = new FilterCriteriaBuilder();
    builder
      .startDate(startDate)
      .endDate(endDate)
      .sites(sites)
      .incidentPriority(incidentPriorities)
      .includeComment(includeComment)
      .includeFiles(includeFiles)
      .includeOnDemand(includeOnDemand)
      .incidentTypes(incidentTypes)
      .source(getSources())
      .operator(operators)
      .resultView(resultView);

    if (!firstResponseDisabled.getValue()) {
      builder.firstResponse(firstResponse);
    }

    if (!timeOfResponseDisabled.getValue()) {
      builder.timeOfResponse(timeOfResponse);
    }

    return builder.build();
  };

  const callIncidentsByCriteriaAPI = async (criteria) => {
    const result: IncidentSearchResultDTO =
      await DataExplorerService.findIncidentsByCriteria(criteria);
    if (result && result?.incidentList) {
      currentIncidentSearchResult.next(result);
      currentGroupInfoSearchResult.next(null);
      sunburstQuery.next(null);
    }
    searchLoading.next({ isLoading: false });
  };

  const setFilterLevelsIfNone = (
    criteria: FilterCriteria
  ): GroupInfoFilterCriteria => {
    const updatedCriteria = criteria as GroupInfoFilterCriteria;
    updatedCriteria.filterLevels = defaultFilterLevels;
    return updatedCriteria;
  };

  const callGroupInfoByCriteriaAPI = async (criteria: FilterCriteria) => {
    const groupedCriteria: GroupInfoFilterCriteria =
      setFilterLevelsIfNone(criteria);
    const result: GroupInfoSearchResult =
      await DataExplorerService.getGroupedInformationByCriteria(
        groupedCriteria
      );
    if (result && result instanceof GroupInfoSearchResult) {
      currentIncidentSearchResult.next(null);
      currentGroupInfoSearchResult.next(result);
      selectedGroupInfoSearchResult.next(null);
      searchLoading.next({ isLoading: false });
      sunburstQuery.next(null);
    }
  };

  const handleAPIError = <T extends Error>(error: T) => {
    if (error instanceof IncidentListTooLargeError) {
      incidentListLimitReached.next(error);
    } else {
      alertSubject.next({ title: translate("filters.searchFailed") });
    }
    currentIncidentSearchResult.next(null);
    currentGroupInfoSearchResult.next(null);
  };

  const callSearchApi = async () => {
    selectedIncidentId.next(null);
    searchLoading.next({ isLoading: true });
    resultsPanelHidden.next(false);
    sunburstQuery.next(null);
    const criteria = prepareCriteria();
    try {
      if (resultView === ResultViewEnum.GROUPED) {
        await callGroupInfoByCriteriaAPI(criteria);
      } else {
        await callIncidentsByCriteriaAPI(criteria);
      }
      incidentListLimitReached.next(undefined);
    } catch (error) {
      handleAPIError(error);
    }
    searchLoading.next({ isLoading: false });
  };

  useEffect(() => {
    const map = new Map<string, Array<string>>();
    map.set(SourceTypeEnum.CAMERA, sourceHolderCamera);
    map.set(SourceTypeEnum.DOOR, sourceHolderDoor);
    map.set(SourceTypeEnum.SENSOR, sourceHolderSensor);
    map.set(SourceTypeEnum.OTHER, sourceHolderOther);
    sourceHolder.next(map);

    filterCriteriaHolder.next(prepareCriteria());

    if (clearIsClicked) {
      filterPanelHidden.next(true);
      setTimeout(() => {
        filterPanelHidden.next(false);
        setClearIsClicked(false);
      }, 1);
    }
  });

  const sitesOnChangeCallback = (res: SitesOptions[]) => {
    if (res) {
      const selectedSites = new Array<string>();

      res.forEach((site) => {
        selectedSites.push(site.id as string);
      });

      if (selectedSites.length > 0) {
        setSites(selectedSites);
      } else {
        setSites([]);
      }
    }
  };

  const handleChangeStartDate = (startDateInput) => {
    setStartDate(startDateInput);
  };

  const handleChangeEndDate = (endDateInput) => {
    setEndDate(endDateInput);
  };

  useEffect(() => {
    setIncidentPriorities(incidentPriorities);
  }, [incidentPriorities]);

  const incidentPrioritiesCallback = useCallback((incidentsInput: string[]) => {
    setIncidentPriorities(incidentsInput);
  }, []);

  const incidentTypesOnChangeCallBack = useCallback(
    (selectedIncidentTypes: IncidentTypeDTO[]) => {
      setIncidentTypes(selectedIncidentTypes.map((incident) => incident.name));
    },
    []
  );

  const firstResponseOnChangeCallBack = useCallback((res) => {
    const resCallback = res as ResponseTimeInterval;

    if (resCallback.min != null || resCallback.max != null) {
      setFirstResponse(resCallback);
    }
  }, []);

  const timeOfResponseOnChangeCallBack = useCallback((res) => {
    const resCallback = res as ResponseTimeInterval;
    if (resCallback.min != null || resCallback.max != null) {
      setTimeOfResponse(resCallback);
    }
  }, []);

  const disableCallbackFirstResponse = useCallback((res) => {
    firstResponseDisabled.next(!res);
  }, []);

  const disableCallbackTimeOfResponse = useCallback((res) => {
    timeOfResponseDisabled.next(!res);
  }, []);

  const clearFilter = () => {
    setSites([]);
    setStartDate(getInitStartDate(null));
    setEndDate(new Date());
    setIncidentPriorities([]);
    setIncludeComment(false);
    setIncludeFiles(false);
    setIncludeOnDemand(false);
    setOperators([]);
    setIncidentTypes([]);
    setSourceHolderCamera([]);
    setSourceHolderDoor([]);
    setSourceHolderSensor([]);
    setSourceHolderOther([]);
    setFirstResponse({ min: 0, max: 20 });
    firstResponseDisabled.next(true);
    setTimeOfResponse({ min: 0, max: 20 });
    timeOfResponseDisabled.next(true);
  };

  useEffect(() => {
    const stateHolder = JSON.parse(
      sessionStorage.getItem(sessionStorageIdentifiers.STATE_HOLDER)
    ) as StateHolder;
    if (organizationId && stateHolder?.org !== organizationId) {
      sessionStorage.removeItem(sessionStorageIdentifiers.STATE_HOLDER);
      clearFilter();
      selectedIncidentId.next(null);
      currentIncidentSearchResult.next(null);
      resultsPanelHidden.next(true);
      filterPanelHidden.next(false);
      detailsPanelHidden.next(true);
    }
  }, [organizationId]);

  const decodeHtmlEntities = (text: string): string => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };

  const validInputs = () => {
    if (!startDate || !isValidDate(startDate, finalDateFormat)) {
      alertSubject.next({
        title: decodeHtmlEntities(
          translate("filters.invalidStartDateInput", {
            dateFormat: finalDateFormat,
          })
        ),
      });
      return false;
    }

    if (!endDate || !isValidDate(endDate, finalDateFormat)) {
      alertSubject.next({
        title: decodeHtmlEntities(
          translate("filters.invalidEndDateInput", {
            dateFormat: finalDateFormat,
          })
        ),
      });
      return false;
    }

    if (startDate && endDate && startDate > endDate) {
      alertSubject.next({ title: translate("filters.invalidEndDate") });
      return false;
    }
    return true;
  };

  const filterLoadingError = () => {
    alertSubject.next({ title: translate("filters.failedToLoad") });
    setHasError(true);
  };

  useEffect(() => {
    if (!hasError && callback) {
      callback(true);
    } else {
      callback(false);
    }
  }, [hasError]);
  return (
    <div className="criteria-container">
      <div className="criteria-content">
        <Sites
          selected={sites}
          onChangeCallback={(response) => sitesOnChangeCallback(response)}
          failedToLoadCallback={filterLoadingError}
        />
        <div
          style={{
            paddingBottom: "var(--msi-ui-spacing-s)",
            paddingTop: "var(--msi-ui-spacing-s)",
          }}
        />
        <TimePeriods
          includeTime={includeTime}
          isEndDateCurrent
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={handleChangeStartDate}
          onChangeEndDate={handleChangeEndDate}
        />
        <Priorities
          priorities={incidentPriorities}
          onChangeCallbackPriorities={incidentPrioritiesCallback}
        />
        <div className="row">
          <div className="fp-col-8">
            <Checkbox
              label={translate("filters.AddedComments")}
              key="includeComments"
              checked={includeComment}
              onChange={(_, checked) => setIncludeComment(checked)}
            />
          </div>
          <div className="col-4" />
        </div>
        <div className="row">
          <div className="fp-col-8">
            <Checkbox
              label={translate("filters.AddedFiles")}
              key="includeFiles"
              checked={includeFiles}
              onChange={(_, checked) => setIncludeFiles(checked)}
            />
          </div>
          <div className="col-4" />
        </div>
        <div className="row">
          <div className="fp-col-8">
            <Checkbox
              label={translate("filters.onDemand")}
              key="includeOnDemand"
              checked={includeOnDemand}
              onChange={(_, checked) => setIncludeOnDemand(checked)}
            />
          </div>
          <div className="col-4" />
        </div>
        <IncidentTypes
          selected={incidentTypes}
          onChangeCallback={incidentTypesOnChangeCallBack}
        />
        <div
          style={{
            paddingBottom: "var(--msi-ui-spacing-s)",
            paddingTop: "var(--msi-ui-spacing-s)",
          }}
        />
        <FirstResponse
          disable={firstResponseDisabled.getValue()}
          disableCallback={disableCallbackFirstResponse}
          selected={firstResponse}
          minValue={0}
          maxValue={20}
          onChangeCallback={firstResponseOnChangeCallBack}
        />
        <div
          style={{
            paddingBottom: "var(--msi-ui-spacing-s)",
            paddingTop: "var(--msi-ui-spacing-s)",
          }}
        />
        <TimeOfResponse
          disable={timeOfResponseDisabled.getValue()}
          selected={timeOfResponse}
          minValue={0}
          maxValue={20}
          disableCallback={disableCallbackTimeOfResponse}
          onChangeCallback={timeOfResponseOnChangeCallBack}
        />
      </div>

      <div className="criteria-footer">
        <div data-cy="filter-clear-button">
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              setClearIsClicked(true);
              clearFilter();
            }}
          >
            {translate("filters.clear")}
          </Button>
        </div>
        <div data-cy="filter-search-button">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (validInputs()) {
                callSearchApi().then();
              }
            }}
          >
            {translate("filters.showResults")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilteringCriteria;
