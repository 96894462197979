import React from "react";
import {
  getMinimalIncidentId,
  Incident,
  safelyFormatDate,
} from "compass-commons";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const IncidentHeaderContent = (props: { incident: Incident }): JSX.Element => {
  const { incident } = props;

  return (
    <>
      {incident && (
        <div className="timeline-header-content-main">
          <span className="timeline-title-date">
            {safelyFormatDate(incident.creationTimestamp)}
          </span>
          {incident.id && (
            <span className="timeline-bold" id="incident-code">
              {getMinimalIncidentId(incident.incidentId)}
            </span>
          )}
          {incident.incidentDescription && (
            <span className="timeline-incident-description">
              {incident.incidentDescription}
            </span>
          )}
          {incident.incidentSource && (
            <span className="timeline-bold">{incident.incidentSource}</span>
          )}
          {incident.location && (
            <div className="timeline-location">
              <span>
                <LocationOnIcon fontSize="medium" color="primary" />
              </span>
              <span>{incident.location}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default IncidentHeaderContent;
