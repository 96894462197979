import React, { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "@msi/cobalt-react/spinner";
import "./resultsPanel.module.css";
import {
  GroupInfoFilterCriteria,
  ResultViewEnum,
  sessionStorageIdentifiers,
  useI18n,
} from "compass-commons";
import { RadioButton, RadioGroup } from "dms-lib";
import { FormControlLabel, Switch } from "@mui/material";
import { IncidentSearchResultDTO } from "../../models/filters/IncidentSearchResultDTO";
import { useStateContext } from "../../contexts/StateContext";
import StateService from "../../services/StateService";
import ResultsChart from "./components/resultsChart/ResultsChart";
import ResultTable from "./components/resultTable/ResultTable";
import ResultsGroupInfo from "./components/resultsGroupInfo/ResultsGroupInfo";
import GroupInfoSearchResult from "../../models/incidents/GroupInfoSearchResult";
import DataExplorerService from "../../services/DataExplorerService";
import { defaultFilterLevels } from "../../util/Constants";
import { StateHolder } from "../../models/state/StateHolder";
import IncidentListTooLargeError from "../../errors/IncidentListTooLargeError";
import NoResults from "./components/noResults/NoResults";
import ResultsFooter from "./components/resultsFooter/ResultsFooter";

const ResultsPanel = (): JSX.Element => {
  const { t: translate } = useI18n();
  const [orderByPriority, setOrderByPriority] = useState(true);
  const [mergeSites, setMergeSite] = useState(true);
  const [ringLimit] = useState(3);
  const [selectedButtonGroup, setSelectedButtonGroup] = useState(
    ResultViewEnum.GRAPHIC
  );
  const stateService: StateService = useStateContext();
  const {
    searchLoading,
    currentIncidentSearchResult,
    currentGroupInfoSearchResult,
    selectedResultsView,
    filterCriteriaHolder,
    sunburstQuery,
    selectedGroupInfoSearchResult,
    incidentListLimitReached,
  } = stateService;

  const [loading, setLoading] = useState(searchLoading.value?.isLoading);
  const [incidentsLimitReached, setIncidentsLimitReached] = useState(
    incidentListLimitReached?.value
  );
  const [searchResult, setSearchResult] =
    useState<IncidentSearchResultDTO>(null);
  const [groupInfoResult, setGroupInfoResult] =
    useState<GroupInfoSearchResult>(null);

  const showNotImplementedFilters = false;
  const ref = useRef(null);

  const getGroupInfoData = async () => {
    const filterCriteria =
      filterCriteriaHolder.value as GroupInfoFilterCriteria;

    if (filterCriteria != null && currentGroupInfoSearchResult.value == null) {
      searchLoading.next({ isLoading: true });
      filterCriteria.filterLevels = defaultFilterLevels;

      DataExplorerService.getGroupedInformationByCriteria(filterCriteria)
        .then((res) => {
          currentGroupInfoSearchResult.next(res);
          selectedGroupInfoSearchResult.next(null);
          searchLoading.next({ isLoading: false });
        })
        .catch(() => searchLoading.next({ isLoading: false }));
    }
  };

  const handleIncidentsError = <T extends Error>(error: T) => {
    if (error instanceof IncidentListTooLargeError) {
      incidentListLimitReached.next(error);
    }
  };

  const getGraphData = async () => {
    const filterCriteria = filterCriteriaHolder.value;
    const sunburstCriteria = sunburstQuery.value;

    if (
      (sunburstCriteria || filterCriteria) &&
      currentIncidentSearchResult.value == null &&
      !incidentsLimitReached
    ) {
      const query = sunburstCriteria || filterCriteria;
      searchLoading.next({ isLoading: true });

      DataExplorerService.findIncidentsByCriteria(query)
        .then((res) => {
          currentIncidentSearchResult.next(res);
          searchLoading.next({ isLoading: false });
          incidentListLimitReached.next(null);
        })
        .catch(<T extends Error>(error: T) => {
          handleIncidentsError(error);
          currentIncidentSearchResult.next(null);
          searchLoading.next({ isLoading: false });
        });
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem(sessionStorageIdentifiers.STATE_HOLDER)) {
      const stateHolder = JSON.parse(
        sessionStorage.getItem(sessionStorageIdentifiers.STATE_HOLDER)
      ) as StateHolder;
      if (stateHolder.incidentSearchResult) {
        if (stateHolder.incidentSearchResult) {
          currentIncidentSearchResult.next(stateHolder.incidentSearchResult);
        }

        if (stateHolder.groupInfoSearchResult) {
          currentGroupInfoSearchResult.next(stateHolder.groupInfoSearchResult);
        }

        if (stateHolder.resultView) {
          selectedResultsView.next(stateHolder.resultView);
          setSelectedButtonGroup(stateHolder.resultView);
        }
      }

      stateHolder.groupInfoSearchResult = null;
      stateHolder.incidentSearchResult = null;
      stateHolder.resultView = null;
      sessionStorage.setItem(
        sessionStorageIdentifiers.STATE_HOLDER,
        JSON.stringify(stateHolder)
      );

      // TODO - eventually implement filters
    }
  }, [
    stateService,
    currentIncidentSearchResult,
    searchLoading,
    currentGroupInfoSearchResult,
  ]);

  useEffect(() => {
    setSearchResult(currentIncidentSearchResult.value);
    setGroupInfoResult(currentGroupInfoSearchResult.value);
    setLoading(searchLoading.value?.isLoading);
    const subscription = currentIncidentSearchResult.subscribe((val) => {
      setSearchResult(val);
      searchLoading.next(null);
    });

    const subscriptionGroupInfo = currentGroupInfoSearchResult.subscribe(
      (val) => {
        setGroupInfoResult(val);
        searchLoading.next(null);
      }
    );

    const subscriptionLoading = searchLoading.subscribe((val) => {
      if (val != null) {
        setLoading(val.isLoading);
      }
    });

    const subscriptionGraphicsEnabled = incidentListLimitReached.subscribe(
      setIncidentsLimitReached
    );

    return function cleanup() {
      subscription.unsubscribe();
      subscriptionLoading.unsubscribe();
      subscriptionGroupInfo.unsubscribe();
      subscriptionGraphicsEnabled.unsubscribe();
    };
  }, [
    stateService,
    currentIncidentSearchResult,
    searchLoading,
    currentGroupInfoSearchResult,
  ]);

  const getGraphView = useCallback(() => {
    if (incidentsLimitReached) {
      return (
        <div data-cy="results-view-too-many" className="results-view__too-many">
          <img
            className="results-view__too-many__img"
            src={`${appConfig.ASSET_STORE}images/incidents_example.png`}
            alt="incidents-example"
          />
          <span className="results-view__too-many__text">
            <span>
              {translate("results.graphNotAvailable", {
                incidentCount: incidentsLimitReached?.incidentCount,
              })}
            </span>
          </span>
        </div>
      );
    }

    if (searchResult?.incidentList?.length > 0) {
      return (
        <div className="results-view">
          <ResultsChart
            incidentPointsList={searchResult?.incidentList}
            isOrderByPriority={orderByPriority}
          />
        </div>
      );
    }

    return <NoResults />;
  }, [incidentsLimitReached, searchResult, orderByPriority]);
  const getGroupView = () => {
    if (groupInfoResult && groupInfoResult.children?.length > 0) {
      return (
        <div className="results-view">
          <ResultsGroupInfo groupInfo={groupInfoResult} ringLimit={ringLimit} />
        </div>
      );
    }
    return <NoResults />;
  };

  const getTableView = () => {
    return (
      <div className="results-view">
        <ResultTable resultPanelRef={ref} />
      </div>
    );
  };

  const handleRadioButtonChange = async (buttonValue: ResultViewEnum) => {
    setSelectedButtonGroup(buttonValue);
    selectedResultsView.next(buttonValue);
    searchLoading.next({ isLoading: false });

    if (buttonValue === ResultViewEnum.GRAPHIC) {
      await getGraphData();
    } else if (buttonValue === ResultViewEnum.GROUPED) {
      await getGroupInfoData();
    }
  };

  const handleOrderByPriority = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderByPriority(event.target.checked);
  };

  const handleMergeSites = (mergeValue) => {
    setMergeSite(mergeValue);
  };

  const Header = useCallback(
    (headerProps) => {
      const { disabled } = headerProps;
      const getIncidentListCount = () => {
        return (
          <b>{searchResult != null ? searchResult.totalRecords : 0}&nbsp;</b>
        );
      };

      return (
        <div className="results-header-main-div">
          <div className="results-header-div">
            <div className="results-header-toggles compass-rounded-corner">
              <RadioGroup
                row
                value={selectedButtonGroup}
                onChange={(buttonValue: ResultViewEnum) =>
                  handleRadioButtonChange(buttonValue)
                }
              >
                <RadioButton
                  id="radio-graphics"
                  data-cr="radio-button"
                  label={translate("results.graphics")}
                  value={ResultViewEnum.GRAPHIC}
                  disabled={disabled}
                />
                {showNotImplementedFilters && (
                  <RadioButton
                    id="radio-group-information"
                    data-cr="radio-button"
                    label={translate("results.groupInformation")}
                    value={ResultViewEnum.GROUPED}
                    disabled={disabled}
                  />
                )}
                <RadioButton
                  id="radio-table"
                  data-cr="radio-button"
                  label={translate("results.table")}
                  value={ResultViewEnum.TABLE}
                  disabled={disabled}
                />
              </RadioGroup>
            </div>
            {selectedButtonGroup === ResultViewEnum.GRAPHIC && (
              <>
                <div className="results-header-toggles compass-rounded-corner">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={orderByPriority}
                        onChange={handleOrderByPriority}
                        disabled={disabled}
                      />
                    }
                    label={translate("results.perPriority")}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={mergeSites}
                        onChange={handleMergeSites}
                        disabled
                      />
                    }
                    label={translate("results.mergeSites")}
                  />
                </div>
                <div className="results-header-count-div ">
                  <span className="results-header-count compass-rounded-corner">
                    {getIncidentListCount()} results
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      );
    },
    [selectedButtonGroup, searchResult, orderByPriority, mergeSites]
  );
  return (
    <div className="results-panel-main-div compass-fade-in-smooth">
      <div ref={ref} className="results-panel">
        <Header />
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "20%",
            }}
          >
            <Spinner size="large" />
          </div>
        ) : (
          <>
            {selectedButtonGroup === ResultViewEnum.GRAPHIC && getGraphView()}
            {selectedButtonGroup === ResultViewEnum.GROUPED && getGroupView()}
            {selectedButtonGroup === ResultViewEnum.TABLE && getTableView()}
            <ResultsFooter
              searchResult={searchResult}
              incidentsLimitReached={incidentsLimitReached}
              selectedButtonGroup={selectedButtonGroup}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ResultsPanel;
