import React from "react";
import ReactDOM from "react-dom";
import singleSpaCss from "single-spa-css";
import singleSpaReact from "single-spa-react";
import { ThemeProvider } from "@msi/pivot-ux/esm/mui";
import "@msi/cobalt-react/css/cobalt-light-core.min.css";
import "@msi/cobalt-styles/scss/overlay-prebuilt.scss";
import "./assets/css/global.css";
import "bootstrap/dist/css/bootstrap.css";
import "dms-lib/lib/esm/dms-lib.css";
import { ICompassI18n, LocalizationNS } from "compass-commons";
import { CompassI18n, defaultNS } from "compass-shared-services";
import { DMSTheme } from "dms-lib";
import App from "./App";

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: true,
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent(props) {
    return (
      <ThemeProvider>
        <DMSTheme theme="pivot">
          <App {...props} />
        </DMSTheme>
      </ThemeProvider>
    );
  },
  errorBoundary(_err, _info, _props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const reactLifecyclesMountWithCompass = async <
  T extends { CompassI18n: ICompassI18n }
>(
  props: T
): Promise<any> => {
  const compassI18n = CompassI18n(appConfig.ASSET_STORE);
  await compassI18n.init({ ns: defaultNS });
  await compassI18n.loadMoreTranslations(LocalizationNS.DATA_EXPLORER);
  // eslint-disable-next-line no-param-reassign
  props.CompassI18n = compassI18n as ICompassI18n;
  return reactLifecycles.mount(props);
};

export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];
export const mount = [cssLifecycles.mount, reactLifecyclesMountWithCompass];
export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
