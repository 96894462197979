import React from "react";
import "./verticalTimeline.module.css";
import { Incident, useI18n } from "compass-commons";
import TimelineHeader from "./TimelineHeader/TimelineHeader";
import TimelineSteps from "./TimelineSteps/TimelineSteps";
import IncidentHeaderContent from "./TimelineHeader/IncidentHeaderContent";

interface VerticalTimelineProps {
  incident: Incident;
  baseColor: string;
}

const headerContent = (incident: Incident): JSX.Element => {
  if (incident) {
    return <IncidentHeaderContent incident={incident} />;
  }
  return null;
};

const VerticalTimeline = (props: VerticalTimelineProps): JSX.Element => {
  const { incident, baseColor } = props;
  const { t: translate } = useI18n();

  return (
    <div
      className="timeline-container compass-fade-in-smooth"
      data-cr="vertical-timeline-component"
    >
      {incident != null ? (
        <div data-cr="timeline-body" className="timeline-body">
          <TimelineHeader
            baseColor={baseColor}
            incident={incident}
            content={headerContent(incident)}
          />
          <TimelineSteps
            steps={incident.systemSteps}
            baseColor={baseColor}
            isSystemStep
            executionId={incident.executionId}
          />
          <TimelineSteps
            steps={incident.operatorSteps}
            baseColor={baseColor}
            isSystemStep={false}
            executionId={incident.executionId}
          />
          {/*  ADD HERE BUTTONS FOR ADDING STEPS */}
        </div>
      ) : (
        <div className="timeline-no-incident" data-cr="timeline-no-incident">
          <span>{translate("details.noIncidentSelected")}</span>
        </div>
      )}
    </div>
  );
};

export default VerticalTimeline;
