// General
import React, { useState } from "react";
import {
  IncidentStep,
  InfoFieldPhoto,
  InfoFieldText,
  InfoFieldUrl,
  ResourceInteractionSource,
  safelyFormatDate,
  useI18n,
  User,
} from "compass-commons";
// Components
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// Models
import IconButton from "@mui/material/IconButton";
import { Avatar, Tooltip } from "@mui/material";
import ActivationsTable from "../../../commons/activationsTable/ActivationsTable";
import ResourceInteractionStep from "../../../../models/resource/ResourceInteractionStep";
import { useStateContext } from "../../../../contexts/StateContext";
import GenericInfoCardTask from "./genericInfoCardTask/GenericInfoCardTask";

const getTranslated = (configText) => {
  const { t: translate } = useI18n();

  return translate(configText);
};
const getWithOperatorIcon = (content: JSX.Element, user: User): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <Tooltip
        title={`${user.firstName} ${user.lastName ? user.lastName : ""} ${
          user.email ? `(${user.email})` : ""
        }`}
        placement="right"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-14, -14],
              },
            },
          ],
        }}
      >
        <Avatar
          sx={{
            width: 30,
            height: 30,
            bgcolor: "var(--operatorIconBlueColor)",
            fontSize: "small",
          }}
        >
          {user.nameInitials}
        </Avatar>
      </Tooltip>
      <div style={{ paddingLeft: "5%", flex: "1" }}>{content}</div>
    </div>
  );
};

const getResourceInteractionSourceText = (
  interactionSource: ResourceInteractionSource
): string => {
  switch (interactionSource) {
    case ResourceInteractionSource.SITE_LAYOUT: {
      return getTranslated("resources.interactionTypeSiteLayout");
    }
    case ResourceInteractionSource.RELATED_RESOURCE: {
      return getTranslated("resources.interactionTypeRelatedResource");
    }
    case ResourceInteractionSource.OPERATOR_GUIDE_TASK: {
      return getTranslated("resources.interactionTypeOperatorGuideTask");
    }
    default: {
      return getTranslated("resources.interactionTypeOperatorGuideTask");
    }
  }
};

const getSpan = (
  value?: string,
  className?: string,
  withBreakLine = false
): JSX.Element => {
  if (value && value.length > 0) {
    return (
      <>
        {withBreakLine && <br />}
        <span className={className}>{value}</span>
      </>
    );
  }
  return <></>;
};

const getDefaultTextSpan = (value?: string): JSX.Element => {
  return getSpan(value, "timeline-default-text");
};

const getTimestampFormat = (clearTimestamp: string): JSX.Element => {
  return (
    <>
      {getSpan(safelyFormatDate(clearTimestamp), "timeline-small-text")}
      <br />
    </>
  );
};

const getPostAlarmContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {incidentStep.title
        ? getDefaultTextSpan(incidentStep.title)
        : getDefaultTextSpan(incidentStep.type)}
      {incidentStep.device && incidentStep.clearTimestamp && (
        <>
          <br />
          <span className="timeline-small-text">
            {incidentStep.device} -{" "}
            {safelyFormatDate(incidentStep.clearTimestamp)}
          </span>
        </>
      )}
      {incidentStep.creationTimestamp && (
        <>
          <br />
          <span className="timeline-small-text">
            Available at {safelyFormatDate(incidentStep.creationTimestamp)}
          </span>
        </>
      )}
    </div>
  );
};

const getGenericContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {getTimestampFormat(incidentStep.clearTimestamp)}
      {incidentStep.title
        ? getDefaultTextSpan(incidentStep.title)
        : getDefaultTextSpan(incidentStep.type)}
      {getSpan(incidentStep.description, "timeline-small-text", true)}
      {getSpan(incidentStep.device, "timeline-small-text", true)}
      {getSpan(incidentStep.instruction, "timeline-small-text", true)}
      {incidentStep.userText && (
        <>
          {" "}
          <span className="timeline-user-text">{incidentStep.userText}</span>
        </>
      )}
    </div>
  );
};

const getAssignedContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div className="timeline-header-content-main" data-cr={incidentStep.type}>
      {getDefaultTextSpan(incidentStep.title)}
      {getSpan(incidentStep.device, "timeline-small-text", true)}
      {incidentStep.clearTimestamp && (
        <>
          <span className="timeline-small-text">
            <b>{safelyFormatDate(incidentStep.clearTimestamp)}</b>
          </span>
        </>
      )}
    </div>
  );
};

const getVideoContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {incidentStep.title
        ? getDefaultTextSpan(incidentStep.title)
        : getDefaultTextSpan(incidentStep.type)}
      {getSpan(incidentStep.device, "timeline-small-text", true)}
      {getSpan(
        safelyFormatDate(incidentStep.clearTimestamp),
        "timeline-small-text",
        true
      )}
      {incidentStep.creationTimestamp && (
        <>
          <br />
          <span className="timeline-small-text">
            Available at {safelyFormatDate(incidentStep.creationTimestamp)}
          </span>
        </>
      )}
    </div>
  );
};

const getManualTaskGenericInfoCardContent = (
  genericInfoList: (InfoFieldPhoto | InfoFieldText | InfoFieldUrl)[]
): JSX.Element => {
  return <GenericInfoCardTask infoFieldDTOs={genericInfoList} />;
};

const getManualContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {getTimestampFormat(incidentStep.clearTimestamp)}
      {getDefaultTextSpan(incidentStep.title)}
      {getSpan(incidentStep.device, "timeline-small-text", true)}
      {getDefaultTextSpan(incidentStep.instruction)}
      {incidentStep.genericInfoList?.length > 0 &&
        getManualTaskGenericInfoCardContent(incidentStep.genericInfoList)}
      {incidentStep.userText &&
        getSpan(
          incidentStep.userText,
          "timeline-user-text",
          incidentStep.genericInfoList?.length <= 0
        )}
    </div>
  );
};

const getCommentContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {getTimestampFormat(incidentStep.clearTimestamp)}
      {incidentStep.title
        ? getDefaultTextSpan(incidentStep.title)
        : getDefaultTextSpan(incidentStep.type)}
      {incidentStep.userText && (
        <>
          <br />
          <div className="timeline-user-comment">
            <span className="timeline-user-comment-inner">
              {incidentStep.userText}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const getClearContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <b data-cr={incidentStep.type}>
      Clear | {safelyFormatDate(incidentStep.clearTimestamp)}
    </b>
  );
};

const getActivationGroupContent = (incidentStep: IncidentStep): JSX.Element => {
  const { t: translate } = useI18n();
  const textContent = translate("resources.activationsCount", {
    count: incidentStep.activationsCount,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const stateService = useStateContext();
  const { selectedIncident } = stateService;
  return (
    <div data-cr={incidentStep.type}>
      <div>
        {getDefaultTextSpan(textContent)}
        <IconButton size="small" onClick={() => setIsExpanded((prev) => !prev)}>
          {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </div>
      {isExpanded && (
        <ActivationsTable
          incident={selectedIncident.value}
          stepId={incidentStep.id}
        />
      )}
    </div>
  );
};

const getResourceInteractionContent = (
  incidentStep: ResourceInteractionStep
): JSX.Element => {
  return incidentStep.resourceInteraction ? (
    <>
      {getTimestampFormat(incidentStep.clearTimestamp)}
      <div
        className="timeline-user-interaction-content"
        data-cr={incidentStep.type}
      >
        <>
          {incidentStep.resourceInteraction.resourceName && (
            <>
              <br />
              <div className="timeline-user-resource-interaction">
                <span
                  className="timeline-user-resource-interaction-span"
                  data-cr="resource-interaction-span"
                >
                  {incidentStep.resourceInteraction.resourceName}
                </span>
                <span
                  className="timeline-user-resource-interaction-source-span"
                  data-cr="resource-interaction-source-span"
                >
                  {getResourceInteractionSourceText(
                    incidentStep.resourceInteraction.interactionSource
                  )}
                </span>
              </div>
            </>
          )}
        </>
      </div>
    </>
  ) : (
    <>Empty Content</>
  );
};

const getGenericInfoCardContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <>
      {getTimestampFormat(incidentStep.clearTimestamp)}
      <GenericInfoCardTask infoFieldDTOs={incidentStep.genericInfoList} />
    </>
  );
};

export {
  getWithOperatorIcon,
  getPostAlarmContent,
  getGenericContent,
  getAssignedContent,
  getVideoContent,
  getManualContent,
  getCommentContent,
  getClearContent,
  getActivationGroupContent,
  getResourceInteractionContent,
  getGenericInfoCardContent,
};
