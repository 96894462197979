/**
 * ROUTER DATA_EXPLORER HELPERS
 */
// The root path for this configuration microFrontend
/* eslint-disable import/prefer-default-export */
import { isUnity } from "compass-commons";

function getMiddlePath(url: string, first: string, second: string) {
  const regex = new RegExp(`/${first}(.*?)${second}`);
  return regex.exec(url)?.[1] || "/";
}

const url = !isStandalone ? window.location.href : "/report/dataexplorer";
const middlePath = getMiddlePath(url, "report", "dataexplorer");
export const ROOTPATH = `/report${middlePath}dataexplorer`;

export const REPORT_CHECKOUT_PATH = `/report${middlePath}dataexplorer/report-checkout`;

export const SOC_REPORT_CHECKOUT_PATH = `/data-explorer/report-checkout`;

export const getReportCheckoutPath = (): string => {
  // SOC Report Checkout
  if (!isDMS) return `${window.location.origin}${SOC_REPORT_CHECKOUT_PATH}`;
  // Unity Report Checkout
  if (isUnity) return `${window.location.pathname}#${REPORT_CHECKOUT_PATH}`;
  // Default Report Checkout
  return appConfig.COMPASS_REPORT_CHECKOUT_PATH;
};
